<template>
  <div class="common-row">
    <span class="left-view">
      {{ title }}
      <slot name="leftView"></slot>
    </span>
    <span>
      <slot name="button"></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'chat-avatar',
  props: ['title'],
  methods: {}
}
</script>
<style lang="scss">
.common-row {
  display: flex;
  flex-direction: row;
  background-color: #f2f2f2;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #324157;
  .left-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: #101010;
  }
}
</style>
