<i18n src="@/i18n/template/components/ImageUploader.json"></i18n>
<template>
  <div v-if="!multiple" class="file-uploader">
    <img v-if="value" :src="value" />
    <i v-else-if="iconClass" :class="iconClass"></i>
    <i v-else class="el-icon-plus"></i>
    <input
      v-if="!disabled"
      type="file"
      accept="image/jpeg,image/png,image/gif,image"
      @change="onFileChange"
    />
  </div>
  <div v-else>
    <div v-for="(item, index) in value" :key="item" class="file-uploader">
      <img :src="item" />
      <input
        v-if="!disabled"
        type="file"
        accept="image/jpeg,image/png,image/gif"
        @change="onFileChange($event, index)"
      />
    </div>
    <div class="file-uploader">
      <i class="el-icon-plus"></i>
      <input type="file" accept="image/*" @change="onFileChange" />
    </div>
  </div>
</template>

<script>
let CompressorLoader = null
export default {
  name: 'new-image-uploader',
  // value: string 文件url地址， 当多文件上传时 为 string[]
  // multiple: boolean 是否多文件上传， true则 value为 string[], 默认false
  // maxSize 最大存储 单位kb
  props: [
    'value',
    'maxSize',
    'multiple',
    'disabled',
    'fileData',
    'fileSource',
    'iconClass',

    // 限定图片尺寸 {width, height}
    'limitSize',
    /**
     *  压缩设置
        maxWidth?: number,
        maxHeight?: number,
        width?: number,
        height?: number,
        quality?: number,
        disablePngTransparency?: boolean,
     * */
    'compressOption'
  ],
  data() {
    return {}
  },
  methods: {
    async onFileChange(event, index) {
      let file = event.target.files[0]
      let maxSize = this.maxSize
      if (maxSize && file.size / 1000 > maxSize) {
        let maxSizeString = this.maxSize + 'KB'
        if (maxSize >= 1024) {
          maxSizeString = (maxSize / 1024).toFixed(1) + 'MB'
        }
        this.$message.error(this.$t('UploadImageSizeStr') + ' ' + maxSizeString)
        event.target.value = ''
        return
      }
      let previewUrl = await this.getLocalPreviewUrl(file)
      if (this.limitSize) {
        let imgSize = await this.getImageSize(previewUrl)
        if (
          imgSize.width !== this.limitSize.width ||
          imgSize.height !== this.limitSize.height
        ) {
          this.$message.error(this.$t('ImageSizeLimit', this.limitSize))
          return
        }
      }
      let loading = this.$loading()
      let fileTarget = file
      if (this.compressOption) {
        fileTarget = await this.compressImage(file)
      }
      let res = await this.$fileApi.uploadFile(
        fileTarget,
        true,
        this.fileSource
      )
      if (res.data) {
        if (this.multiple) {
          if (index) {
            this.$emit(
              'input',
              this.value.map((item, i) => {
                if (i === index) {
                  return res.data
                }
                return item
              })
            )
          } else {
            this.$emit('input', [...this.value, previewUrl])
            let fileData = this.fileData || []
            this.$emit('update:fileData', [...fileData, res])
          }
        } else {
          this.$emit('input', previewUrl)
          this.$emit('update:fileData', res)
          this.$emit('change', res)
        }
      }
      event.target.value = ''
      loading.close()
    },
    getLocalPreviewUrl(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = event => {
          resolve(event.target.result)
        }
        reader.readAsDataURL(file)
      })
    },
    getImageSize(uri) {
      return new Promise(resolve => {
        const img = new Image()
        img.src = uri
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height
          })
        }
        img.onerror = () => {
          resolve({
            width: 0,
            height: 0
          })
        }
      })
    },
    async compressImage(file) {
      if (!CompressorLoader) {
        CompressorLoader = import(
          /* webpackChunkName: "compressorjs" */ 'compressorjs'
        )
      }
      const res = await CompressorLoader
      const Compressor = res?.default
      if (!Compressor) {
        this.$message.warning('Load failed, Please refresh page and try again')
        throw new Error('compress Load failed')
      }

      return new Promise((resolve, reject) => {
        new Compressor(file, {
          ...this.compressOption,
          success: resolve,
          error: err => {
            console.log(err, 'compress error')
            resolve(file)
          }
        })
      })
    }
  }
}
</script>

<style>
.file-uploader {
  display: inline-block;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.file-uploader:hover {
  border-color: #409eff;
}
.file-uploader i {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.file-uploader img {
  width: 178px;
  height: 178px;
  display: block;
}
.file-uploader input[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
</style>
