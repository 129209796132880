export default [
  {
    path: '/live_monitoring',
    name: 'live_monitoring',
    component: () =>
      import(/* webpackChunkName: "live" */ './LiveMonitoring.vue')
  },
  {
    path: '/live_replay_manager',
    name: 'live_replay_manager',
    component: () =>
      import(/* webpackChunkName: "live" */ './LiveReplayManager.vue')
  },
  {
    path: '/live_time_manager',
    name: 'live_time_manager',
    component: () =>
      import(/* webpackChunkName: "live" */ './LiveTimeManager.vue')
  },
  {
    path: '/live_gift',
    name: 'live_gift',
    component: () =>
      import(/* webpackChunkName: "live_gift" */ './LiveGift.vue')
  }
]
