import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let homeChildren = []
const routerR = require.context('../views', true, /router.js$/)
routerR.keys().forEach(key => {
  let routerConfig = routerR(key).default
  if (Array.isArray(routerConfig)) {
    homeChildren = homeChildren.concat(routerConfig)
  } else {
    homeChildren.push(routerConfig)
  }
  homeChildren = homeChildren.map(item => {
    let asyncFn = item.component
    if (typeof asyncFn === 'function') {
      // keep route name same with component name
      item.component = () =>
        asyncFn().then(res => {
          if (item.name) {
            res.default.name = item.name
          }
          return res
        })
    }
    return item
  })
})

const routes = [
  {
    path: '/',
    redirect: to => {
      return 'todo'
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Index.vue'),
    children: homeChildren
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      // 是否需要登录鉴权
      loginAuth: false
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/oauth',
    name: 'oauth',
    meta: {
      // 是否需要登录鉴权
      loginAuth: false,
      auth: false
    },
    component: () => import(/* webpackChunkName: "oauth" */ '../OAuth.vue')
  },
  {
    path: '*',
    name: '404',
    meta: {
      // 是否需要登录鉴权
      loginAuth: false
    },
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
