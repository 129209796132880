export default [
  {
    path: 'business_service_tracking',
    name: 'BusinessServiceTracking',
    component: () =>
      import(
        /* webpackChunkName: "BusinessServiceTracking" */ '@/projects/teller/views/TransactionMonitoring/BusinessServiceTracking.vue'
      )
  }
]
