import '@/i18n/template/util/util.js'
import { NeverExpiredDate, NeverExpiredTime } from '@/constant'
import i18n from '@/i18n'
import dayjs from 'dayjs'

// 暂存原始的 format 方法
const dayjsFormat = dayjs.prototype.format
// format添加默认格式化值
dayjs.prototype.format = function (template = 'YYYY/MM/DD HH:mm:ss') {
  return dayjsFormat.call(this, template)
}
// format添加默认格式化值
dayjs.prototype.formatDate = function (template = 'YYYY/MM/DD') {
  return dayjsFormat.call(this, template)
}
// convert 20190101 =>>> 2019/01/01
export function cbpDate(date, separate = '/') {
  if (typeof date === 'number') {
    date = date + ''
  } else if (typeof date !== 'string') return ''
  if (date === NeverExpiredDate || date === NeverExpiredTime) {
    return i18n.t('NeverExpire')
  }
  let y = date.substr(0, 4)
  let m = date.substr(4, 2)
  let d = date.substr(6, 2)
  if (date.length === 8 || date.length === 6) {
    return `${y}${separate}${m}${separate}${d}`
  }
  if (date.length === 14) {
    let h = date.substr(8, 2)
    let mm = date.substr(10, 2)
    let s = date.substr(12, 2)
    return `${y}${separate}${m}${separate}${d} ${h}:${mm}:${s}`
  }
  return date
}
// convert 120000 =>>> 12:00:00
export function cbpTime(time, separate = ':') {
  if (typeof time !== 'string' || !time) return ''
  let h = time.substr(0, 2)
  let m = time.substr(2, 2)
  let s = time.substr(4, 2)
  return `${h}${separate}${m}${separate}${s}`
}
// convert 2021-12-01 12:00:00 =>>> 20211201120000
// convert 12:00:00 =>>> 120000
export function toCBPTime(time) {
  if (typeof time !== 'string') return ''
  return time.replace(/[^\d]/g, '')
}
// convert 2019-01-01 =>>> 20190101
export const toCBPDate = toCBPTime

export function toBizTime(time) {
  let t = dayjs(time)
  if (t.isValid()) {
    return t.format('YYYY-MM-DD HH:mm:ss')
  }
  return ''
}

export function random(length) {
  var str = Math.random().toString(36).substr(2)
  if (str.length >= length) {
    return str.substr(0, length)
  }
  str += random(length - str.length)
  return str
}

export function getFileType(filename = '') {
  let fileType = 'other'
  if (filename.match(/\.mp4$/i)) {
    fileType = 'video'
  } else if (filename.match(/\.(mp3)$/i)) {
    fileType = 'audio'
  } else if (filename.match(/\.(jpe?g|png|gif)/i)) {
    fileType = 'image'
  }
  return fileType
}
export function humanSize(size, ignorBit) {
  if (!size) return ''
  if (size < 1024) {
    if (ignorBit) {
      return '0KB'
    }
    return size + 'B'
  }
  size = size / 1024
  if (size > 1024) {
    size = (size / 1024).toFixed(1) + 'MB'
  } else {
    size = size.toFixed(1) + 'KB'
  }
  return size
}

export function saveBlobFile(blob, filename) {
  if (navigator.msSaveBlob) {
    // For ie and Edge
    return navigator.msSaveBlob(blob, filename)
  } else {
    let link = document.createElement('a')
    link.style = 'display: none'
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.dispatchEvent(
      new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
    )
    link.remove()
    window.URL.revokeObjectURL(link.href)
  }
}
export function downloadFile(url, filename = '') {
  let link = document.createElement('a')
  link.style = 'display: none'
  link.href = url
  link.download = filename
  document.body.appendChild(link)
  link.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  )
  link.remove()
}

export function getLabel(value, options = []) {
  if (typeof value !== 'string' && typeof value !== 'number') return ''
  for (let item of options) {
    if (item.value === value) {
      return item.getLabel ? item.getLabel() : item.label
    }
  }
  return value
}

export function getGuid() {
  // 替换最后四个字符为MBL2,作为后端确认设备来源
  let str =
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    '_WEB'
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return str.toUpperCase()
}
export function getReqId() {
  let s1 = Math.floor((1 + Math.random()) * 0x100000000).toString(16)
  let s2 = Math.floor((1 + Math.random()) * 0x100000000).toString(16)
  return s1 + s2 + '-' + Date.now()
}

export function handleNRCData(urls) {
  urls = urls || ''
  const urlArr = urls.split(';')
  let nrcFront = ''
  let nrcBack = ''
  let personal = ''
  // FRONT@xxx;BACK@xxxx;
  if (urls.indexOf('@') !== -1) {
    for (let item of urlArr) {
      const [type, url] = item.split('@')
      if (type === 'FRONT') {
        nrcFront = url
      } else if (type === 'BACK') {
        nrcBack = url
      } else if (type === 'PERSON') {
        personal = url
      }
    }
  } else {
    nrcFront = urlArr[0]
    nrcBack = urlArr[1]
    personal = urlArr[2]
  }
  if (nrcFront === 'null') {
    nrcFront = ''
  }
  if (nrcBack === 'null') {
    nrcBack = ''
  }
  if (personal === 'null') {
    personal = ''
  }
  return {
    nrcFront,
    nrcBack,
    personal
  }
}

/*
 * el-cascader递归获取父级id
 * @param  list 数据列表
 * @param  id 后端返回的id
 * @param  children 子类的key
 * @param  keyStr  idkey  默认 ‘id’
 **/
export function getParentsById(list, id, children, keyStr) {
  let key = keyStr
  if (!key) {
    key = 'id'
  }
  for (let i in list) {
    if (list[i][key] == id) {
      return [list[i][key]]
    }
    if (list[i][children]) {
      let node = getParentsById(list[i][children], id, children)
      if (node !== undefined) {
        node.unshift(list[i][key])
        return node
      }
    }
  }
}

export function printClick() {
  let el = document.getElementById('print_content')
  let doc = document
  let body = doc.body || doc.getElementsByTagName('body')[0]
  let printId = 'print-' + Date.now()
  let content = doc.createElement('div')
  content.id = printId
  let style = doc.createElement('style')
  style.innerHTML =
    'body>#' +
    printId +
    '{display:none}@media print{body>:not(#' +
    printId +
    '){display:none !important}body>#' +
    printId +
    '{display:block;padding-top:1px}}'
  content.innerHTML = el.outerHTML
  body.appendChild(style)
  body.appendChild(content)
  setTimeout(() => {
    window.print()
    body.removeChild(content)
    body.removeChild(style)
  }, 20)
}

export function getTimeDisplayText(tp, value) {
  // Day: '1', Month: '2', Quarter: '3', Year: '4'
  if (tp === '1') {
    return cbpDate(value)
  }
  if (tp === '2') {
    let m = value.substring(4, 6)
    if (m.length === 1) {
      m = '0' + m
    }
    return `${value.substring(0, 4)}-${m}`
  }
  if (tp === '3') {
    const q = value.substring(4, 5)
    const quarterText = {
      1: i18n.t('Quarter1'),
      2: i18n.t('Quarter2'),
      3: i18n.t('Quarter3'),
      4: i18n.t('Quarter4')
    }
    return `${value.substring(0, 4)} ${quarterText[q]}`
  }
  if (tp === '4') {
    return value.substring(0, 4) + ' ' + i18n.t('SCRNITM#yr')
  }
}
